@tailwind base;
@tailwind components;
@tailwind utilities;

.Popover {
  background: #fff;
  color: white;
  border-radius: 10px;
  padding: 4px 6px;
  font-size: 14px;
  text-align: left;
  z-index: 50;
  position: relative;
  box-shadow: 2px 3px 9px -5px rgba(0, 0, 0, 0.21),
		-4px 3px 9px 2px rgba(0, 0, 0, 0.1);
}

.Popover:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -5px;
  height: 15px;
  width: 15px;
  background-color: white;
  z-index: -1;
  transform: rotate(45deg);
  border-radius: 3px;
}

.-z-1{
  z-index: -1;
}

.opacity-2\.5{
  opacity: 0.02;
}

[type="text"],
[type="email"],
[type="password"],
[type="number"],
[type="date"],
[type="search"],
[type="tel"],
textarea,
select {
  font-size: 0.9rem;
  width: 100%;
  border-radius: 6px;
}

[type="text"]:disabled,
[type="text"]:read-only,
[type="email"]:disabled,
[type="email"]:read-only,
[type="password"]:disabled,
[type="password"]:read-only,
[type="number"]:disabled,
[type="number"]:read-only,
[type="date"]:disabled,
[type="date"]:read-only,
[type="search"]:disabled,
[type="search"]:read-only,
[type="tel"]:disabled,
[type="tel"]:read-only,
textarea:disabled,
textarea:read-only,
select:disabled {
  color: #888;
  background-color: #eaeaea;
  border-color: #ccc;
  /* pointer-events: none; */
  cursor: not-allowed;
}

.flatpickr-input:read-only:not(:disabled){
  background-color: #fff;
  cursor: pointer;
}

@layer components {
  .container {
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", Helvetica, Arial, serif;
  }

  body {
    font-family: system-ui, -apple-system, /* Firefox supports this but not yet `system-ui` */ 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
  }

  .negative-shadow{
    box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.05);
  }
}

.dropdown-menu .dropdown-item.w-full {
  width: 100% !important;
}

.sprite {
  display: inline-block;
  vertical-align: middle;
}

.sprite-circle-bottom {
  width: 72px;
  height: 36px;
  background: currentColor;
  border-radius: 0 0 72px 72px;
}

.sprite-circle {
  width: 100px;
  height: 100px;
  background: currentColor;
  border-radius: 50%;
}

.sprite-triangle-equi {
  border-width: 0 82px 142px;
}
.sprite-triangle-equi,
.sprite-triangle-rect {
  border-color: transparent transparent currentcolor;
  border-style: solid;
}

.custom-radio.custom-control,
.custom-checkbox.custom-control {
  z-index: 0 !important;
}
.invoice-preview-wrapper {
  padding-top: 1.5rem;
}

.rdt_TableHeadRow{
  background-color: #f0f0f3 !important;
  color: #777;
  border-bottom-color: #ddd !important;
  min-height: 42px !important;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2px !important;
}

.rdt_TableRow { 
  cursor: pointer;
}

.rdt_TableRow.highlighted {
  background-color: rgba(0,0,0,0.1) !important;
}

@media (max-width: 640px) {
  .d-sm-hidden {
    display: none !important;
  }
}
